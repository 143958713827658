import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import Footer from '../components/Layout/footer';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SEOHeader from '../components/Head';

type Props = {
    data: {
        mdx: {
            body: string;
            slug: string;
            frontmatter: {
                title: string;
                order: number;
                picturesPerLine: number | undefined;
            };
        };
        allMdx: {
            nodes: Array<{
                slug: string;
                frontmatter: {
                    title: string;
                };
            }>;
        };
        allImageSharp: {
            nodes: Array<{
                gatsbyImageData: IGatsbyImageData;
                parent: {
                    relativePath: string;
                };
            }>;
        };
    };
};

const Project: React.FC<Props> = ({
    data: {
        mdx: {
            body,
            slug,
            frontmatter: { title, order, picturesPerLine },
        },
        allMdx: { nodes: projects },
        allImageSharp: { nodes: pictures },
    },
}) => {
    const previousProject = order > 1 ? projects[order - 2] : projects[projects.length - 1];
    const nextProject = order < projects.length ? projects[order] : projects[0];
    const pathRegex = new RegExp(`${slug}/[1-9].png`);
    const projectPictures = pictures
        .filter((picture) => picture.parent.relativePath.match(pathRegex))
        .sort((a, b) =>
            a.parent.relativePath < b.parent.relativePath ? -1 : a.parent.relativePath > b.parent.relativePath ? 1 : 0,
        );

    return (
        <>
            <SEOHeader title={`Pierre Buzulier - Project ${title}`} />
            <main className="container mx-auto p-6 min-h-screen relative pb-24">
                <h2 className="lg:hidden font-serif pb-4 lowercase">{title}</h2>
                <div
                    className={`relative grid grid-cols-1 lg:grid-cols-${
                        picturesPerLine ?? 1
                    } gap-8 lg:gap-20 lg:p-36 lg:pb-0`}
                >
                    <Link to="/" className="absolute top-8 right-0 hidden lg:inline-block">
                        <StaticImage src="../images/cross.png" alt="close" width={20} height={20} />
                    </Link>
                    {projectPictures.map((picture) => (
                        <GatsbyImage key={picture.parent.relativePath} alt={slug} image={picture.gatsbyImageData} />
                    ))}
                    <MDXRenderer>{body}</MDXRenderer>
                </div>
            </main>
            <Footer previousLink={previousProject?.slug} nextLink={nextProject?.slug} />
        </>
    );
};

export default Project;

export const query = graphql`
    query ($id: String) {
        mdx(id: { eq: $id }) {
            body
            slug
            frontmatter {
                title
                order
                picturesPerLine
            }
        }
        allMdx(sort: { fields: frontmatter___order, order: ASC }) {
            nodes {
                slug
                frontmatter {
                    title
                }
            }
        }
        allImageSharp {
            nodes {
                gatsbyImageData
                parent {
                    ... on File {
                        relativePath
                    }
                }
            }
        }
    }
`;
